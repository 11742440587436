import React from 'react'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Intro from './Intro'
import Secondsec from './Secondsec'
import FourthSec from './FourthSec'

import "../ReFunds/ReFunds.css";

const index = () => {
  return (
    <>
        <Header/>
            <React.Fragment>
                <Intro/>
                <Secondsec/>
                <FourthSec/>
            </React.Fragment>
        <Footer/>
    </>
    )
}

export default index
