import React from "react";

const Footer = () => {
  return (
      <footer className="main-footer">
        <strong> &copy; </strong>
        All rights reserved by Fintech.
        <div className="float-right d-none d-sm-inline-block">
          {/* <b>Version</b> 3.2.0 */}
        </div>
      </footer>
  );
}

export default Footer;
