import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Intro from "./Intro";
import Secondsec from "./Secondsec";
import ThirdSec from "./ThirdSec";

import "./Slideshow.css";
import "./Secondsec.css";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>About FinTech Law Firm | Securities Law Firm</title>
        <meta
          name="description"
          content="Learn more about FinTech Law's experienced team of lawyers and legal advisers and how they can help your firm."
        />
      </Helmet>
      <Header />
      <Secondsec />
      <Intro />
      <ThirdSec />
      <Footer />
    </>
  );
};

export default Index;
