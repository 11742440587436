import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Secimg from '../../assets/home-about-lg-graphic.webp'

const Secondsec = () => {
    const [data, setData] = useState([]);

  const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
      const result = await response.json();
      setData(result);
    };

    useEffect(() => {
      fetchData();
    }, []);

  return (
    <section className='second-sec'>
        <Container>
            <Row>
                <Col md={6}>
                {data.map((row, index) => (
                  <React.Fragment key={index}>
                    <div className={'title secondary'}>{row.headingtwo}</div>
                    <p>{row.subheadingtwo}</p>
                  </React.Fragment>
                ))}

                    <Link to={'/about'}>
                     <Button>About Us</Button>
                    </Link>
                </Col>
                <Col md={6}>
                <img src={Secimg} alt={'about-us-sec-img'} width="100%" fetchpriority="low" loading="lazy"/></Col>
            </Row>
        </Container>
    </section>
  )
}

export default Secondsec
