import React, {useState, useEffect} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../ReFunds/ReFunds.css";

const Intro = () => {
    const styles = {
        width: "300px",
        margin: "0 auto",
        textAlign: "center",
    };

    const [data, setData] = useState([]);
    const [membership, setMembership] = useState([]);
    const [partner, setpartner] = useState([]);

    const fetchData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/get-partnership`
        );
        const result = await response.json();
        setData(result);
    };

    const fetchmembership = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/get-membership`
        );
        const result = await response.json();
        setMembership(result);
    };

    const fetchpartnerpage = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/get-partnerpage`
        );
        const result = await response.json();
        setpartner(result);
    };

    useEffect(() => {
        fetchData();
        fetchmembership();
        fetchpartnerpage();
    }, []);

    return (
        <>
            <section className="blogintrosec">
                <Container>
                    <Row className="py-5">
                        <Col>
                            {partner.map((row, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="about-head-top">
                                        FinTech Law Partnerships & Memberships
                                    </h1>
                                    <h2 className="py-3 about-p-top">
                                        Over the years, we've worked to build strong relationships
                                        and opportunities with like-minded organizations.
                                    </h2>
                                </React.Fragment>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="partnership-second-sec">
                <Container>
                    <Row>
                        <Col>
                            {partner.map((row, index) => (
                                <React.Fragment key={index}>
                                    <div className={'title secondary pt-0'}>{row.heading}</div>
                                    <p>{row.subheading}</p>
                                    {/* <Link to={row.buttonone}>
                        <Button variant="outline-light my-5" className="blue-btn refundbtn">JOIN US</Button></Link> */}
                                </React.Fragment>
                            ))}
                        </Col>
                    </Row>
                    <Row className="py-5">
                        {data.map((row, index) => (
                            <Col md={4} key={index} className="partner-image-wrapper">
                                <Link to={row.name}>
                                    <img
                                        fetchpriority={'low'}
                                        loading={'lazy'}
                                        alt={'partner-img'}
                                        className="py-5"
                                        src={
                                            "https://storage.googleapis.com/fintechlegal/posts/" +
                                            row.document
                                        }
                                        style={styles}
                                    />
                                </Link>
                            </Col>
                        ))}
                    </Row>

                    <Row className="py-5">
                        <Col className="py-5 partner-image-wrapper" style={{flexDirection: 'column'}}>
                            {partner.map((row, index) => (
                                <>
                                    <div className={'title secondary pt-0'}>{row.secondsecheading}</div>
                                    <p>{row.secondsecsubheading}</p>
                                </>
                            ))}
                        </Col>
                    </Row>
                    <Row className="py-5">
                        {membership.map((row, index) => (
                            <Col md={6} key={index}>
                                <Link to={row.name}>
                                    <img
                                        fetchpriority={'low'}
                                        loading={'lazy'}
                                        alt={'member-img'}
                                        className="py-5"
                                        src={
                                            "https://storage.googleapis.com/fintechlegal/posts/" +
                                            row.document
                                        }
                                        style={styles}
                                    />
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Intro;
