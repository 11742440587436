import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import Intro from "./Intro";
import Secondsec from "./Secondsec";
import SubscribeForm from "../../Dashboard/Components/SubscribeForm";

import "./About.css";

const index = () => {
  return (
    <>
      <Helmet>
        <title>About FinTech Law Firm | Securities Law Firm</title>
        <meta
          name="description"
          content="Learn more about FinTech Law's experienced team of lawyers and legal advisers and how they can help your firm.
"
        />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <Secondsec />
        <SubscribeForm />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default index;
