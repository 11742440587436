import React from 'react'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Intro from './Intro'
import Secondsec from './Secondsec'
import Thirdsec from './Thirdsec'

const index = () => {
  return (
        <>
    <Header/>
    <React.Fragment>
        <Intro/>
        <Secondsec/>
        <Thirdsec/>
    </React.Fragment>
        <Footer/>
    </>
    )
}

export default index
