import { Col, Container, Row } from "react-bootstrap";

import "./infographics.css";
import "../ReFunds/ReFunds.css";

const Intro = () => {
  return (
    <section className="blogintrosec">
      <Container>
        <Row>
          <Col>
            <>
              <h1 className="infographics-resource-heading">
                {"FinTech Legal Infographics"}
              </h1>
              <p>
                {
                  "Read on to get the latest news and updates regarding the financial tech and regulatory tech industry from the FinTech Law team."
                }
              </p>
            </>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
