import React, {useState, useEffect, useMemo} from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Secondsec = () => {
  const [data, setData] = useState([]);
  const [partner, setPartner] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-team-with-order`
    );
    const result = await response.json();
    if (Array.isArray(result)) setData(result);
  };

  const fetchPartnerPage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-aboutpage`
    );
    const result = await response.json();
    if (Array.isArray(result)) setPartner(result);
  };

  useEffect(() => {
    fetchData();
    fetchPartnerPage();
  }, []);

  // Filter team members where displayOnTeamPage is 'yes'
  const filteredData = useMemo(() => {
    return data.map((row) => {
      return row.displayOnTeamPage === "yes" ? row : null;
    })
  }, [data]);

  return (
    <section className="meet-team-sec">
      <Container>
        {partner.map((row, index) => (
          <div key={index} className="text-center text-white">{ReactHtmlParser(row.desc)}</div>
        ))}
        <div className="title secondary centered pt-0">Get To Know Our Team</div>
        <p className="text-center text-white">
          Here at FinTech Law, our people make all the difference.
        </p>

        <Row>
          {filteredData.map((row, index) => (
            <Col key={index} className="py-3" md={4}>
              <div className="flip-cardt">
                <div className="flip-card-innert">
                  <div className="flip-card-frontt">
                    <img
                        alt={'posts-img'}
                        fetchpriority={'low'}
                        loading={'lazy'}
                      src={
                        "https://storage.googleapis.com/fintechlegal/posts/" +
                        row.document
                      }
                      width={'320px'}
                      height={'320px'}
                    />
                  </div>
                  <div className="flip-card-backt">
                    <div className="flip-card-backt-bg">
                      <div className={'title secondary'}>{row.membername}</div>
                      <p className="flipcardt-position">{row.memberposition}</p>
                      <p className="flipcardt-desc">{row.memberdesc}</p>
                      <div className="icons-container">
                        {row.memberlinkden && (
                          <Link to={row.memberlinkden}>
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              size="2x"
                              className="px-2"
                            />
                          </Link>
                        )}
                        {row.membertwitter && (
                          <Link to={row.membertwitter}>
                            <FontAwesomeIcon
                              icon={faTwitter}
                              size="2x"
                              className="px-2"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Link className="nounderline" to={"/contact"}>
          <Button>Contact FinTech Law Today!</Button>
        </Link>
      </Container>
    </section>
  );
};

export default Secondsec;
