import React, {useState, useEffect, useMemo} from "react";
import {NavLink, Link} from "react-router-dom";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

import Fintechlogo from "../../assets/FintechLogo.svg";
import JootGradient from "../../assets/icon-joot-gradient.webp";
import Facebook from "../../assets/facebook.svg";
import Linkden from "../../assets/linkden.svg";
import Twitter from "../../assets/twitter.svg";
import image1 from "./images/star1.svg";

import "./Header.css";

const Header = () => {
    const [data, setData] = useState([]);
    const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);
    const [aboutDropdownVisible, setAboutDropdownVisible] = useState(false);
    const [toolsDropdownVisible, setToolsDropdownVisible] = useState(false);
    const [resourcesDropdownVisible, setResourcesDropdownVisible] =
        useState(false);

    const fetchData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/get-packages`
        );
        const result = await response.json();
        setData(result);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredData = useMemo(() => {
        return data.map((row) => {
            if (row.packagetitle.toLowerCase().includes("services")) {
                row.packagetitle = row.packagetitle.replace(/services/gi, "");
            }
            return row;
        })
    }, [data]);

    return (
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand>
                    <Link to="/">
                        <img src={Fintechlogo} width="150px" alt="Fintech Logo"/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end flex-grow-1 pe-3 py-3">
                        <NavDropdown
                            title={
                                <Link className="nav-link-url pl-3" to="/tools">
                                    Tools
                                    <img className="new-img" alt={'star-img'} src={image1}/>
                                </Link>
                            }
                            id="tools-dropdown"
                            onMouseEnter={() => setToolsDropdownVisible(true)}
                            onMouseLeave={() => setToolsDropdownVisible(false)}
                            show={toolsDropdownVisible}
                        >
                            <Link className="dropdown-item nav-link-url" to="/tools">
                                FinTech Law Assistant
                            </Link>
                        </NavDropdown>

                        <NavDropdown
                            title={
                                <NavLink className="nav-link-url" to="/services">
                                    Services
                                </NavLink>
                            }
                            id="services-dropdown"
                            className="px-3"
                            onMouseEnter={() => setServicesDropdownVisible(true)}
                            onMouseLeave={() => setServicesDropdownVisible(false)}
                            show={servicesDropdownVisible}
                        >
                            {filteredData.map((row, index) => (
                                <NavLink
                                    key={index}
                                    className="dropdown-item nav-link-url"
                                    to={`/services/${row.slug}`}
                                    onClick={() =>
                                        (window.location.href = `/services/${row.slug}`)
                                    }
                                >
                                    {row.menulabel}
                                </NavLink>
                            ))}
                        </NavDropdown>
                        <NavDropdown
                            title={
                                <Link className="nav-link-url" to="/about">
                                    About
                                </Link>
                            }
                            id="about-dropdown"
                            className="px-3"
                            onMouseEnter={() => setAboutDropdownVisible(true)}
                            onMouseLeave={() => setAboutDropdownVisible(false)}
                            show={aboutDropdownVisible}
                        >
                            <Link className="dropdown-item nav-link-url" to="/about">
                                Meet The Team
                            </Link>
                            <Link className="dropdown-item nav-link-url" to="/partnerships">
                                Our Partnerships
                            </Link>
                        </NavDropdown>
                        <NavDropdown
                            title={
                                <Link className="nav-link-url" to="/resources">
                                    Resources
                                </Link>
                            }
                            id="resources-dropdown"
                            className="px-3"
                            onMouseEnter={() => setResourcesDropdownVisible(true)}
                            onMouseLeave={() => setResourcesDropdownVisible(false)}
                            show={resourcesDropdownVisible}
                        >
                            <Link className="dropdown-item nav-link-url" to="/blog">
                                Blog
                            </Link>
                            <Link
                                className="dropdown-item nav-link-url"
                                to="/resources/compliance-newsletter"
                            >
                                Newsletter
                            </Link>
                            <Link className="dropdown-item nav-link-url" to="/resources/infographics">
                                Infographics
                            </Link>
                            <Link className="dropdown-item nav-link-url" to="/events">
                                Events
                            </Link>
                        </NavDropdown>

                        <Link className="nav-link-url px-3 pr-5 align-content-center" to="/contact">
                            Contact
                        </Link>
                        <a
                            className="nav-link-url px-2"
                            href="https://twitter.com/fintechlawfirm"
                        >
                            <img src={Twitter} alt="Twitter"/>
                        </a>
                        <a
                            className="nav-link-url px-2"
                            href="https://www.facebook.com/fintechlegal"
                        >
                            <img src={Facebook} alt="Facebook"/>
                        </a>
                        <a
                            className="nav-link-url px-2"
                            href="https://www.linkedin.com/company/fintech-law-firm"
                        >
                            <img src={Linkden} alt="LinkedIn"/>
                        </a>
                        <a className="nav-link-url px-2" href="https://joot.io/">
                            <img src={JootGradient} alt="Joot"/>
                        </a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
