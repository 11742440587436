import React from 'react'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Content from './Content'

const index = () => {
  return (
    <>
        <Header/>
        <React.Fragment>
            <Content/>
        </React.Fragment>
        <Footer/>
    </>
    )
}

export default index
