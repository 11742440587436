import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { request } from "../../helpers/fetch-wrapper";

const NewHomepage = () => {
  const [pageName, setPageName] = useState("");
  const [headingOne, setHeadingOne] = useState("");
  const [buttonOne, setButtonOne] = useState("");
  const [headingTwo, setHeadingTwo] = useState("");
  const [subHeadingOne, setSubHeadingOne] = useState("");
  const [buttonTwo, setButtonTwo] = useState("");
  const [descOne, setDescOne] = useState("");
  const [userReviewHeading, setUserReviewHeading] = useState("");
  const [userReviewPosition, setuserReviewPosition] = useState("");
  const [headingThree, setHeadingThree] = useState("");
  const [ctaTitleHeading, setCtaTitleHeading] = useState("");
  const [ctaDesc, setCtaDesc] = useState("");
  const [ctaButton, setCtaButton] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };

  const submitProduct = async () => {
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-page`;
    const body = {
      pageName,
      headingOne,
      buttonOne,
      headingTwo,
      subHeadingOne,
      buttonTwo,
      descOne,
      userReviewHeading,
      userReviewPosition,
      headingThree,
      ctaTitleHeading,
      ctaDesc,
      ctaButton,
    };
    const result = await request({
      method: "post",
      url: baseUrl,
      body,
    });

    if (result) {
      navigate("/pages-list");
    }

    // replace with actual submission logic
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Add New Page</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="adminform-background px-3 mx-3 py-3">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Page name"
                value={pageName}
                onChange={(event) => setPageName(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Heading one"
                value={headingOne}
                onChange={(event) => setHeadingOne(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Button One"
                value={buttonOne}
                onChange={(event) => setButtonOne(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Heading two"
                value={headingTwo}
                onChange={(event) => setHeadingTwo(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Subheading one"
                value={subHeadingOne}
                onChange={(event) => setSubHeadingOne(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Button two"
                value={buttonTwo}
                onChange={(event) => setButtonTwo(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="User view heading"
                value={userReviewHeading}
                onChange={(event) => setUserReviewHeading(event.target.value)}
                fullWidth
              />
              <TextField
                label="User view position"
                value={userReviewPosition}
                onChange={(event) => setuserReviewPosition(event.target.value)}
                fullWidth
              />
              <TextField
                label="Heading three"
                value={headingThree}
                onChange={(event) => setHeadingThree(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Cta title heading"
                value={ctaTitleHeading}
                onChange={(event) => setCtaTitleHeading(event.target.value)}
                fullWidth
              />
              <TextField
                label="Cta description"
                value={ctaDesc}
                onChange={(event) => setCtaDesc(event.target.value)}
                fullWidth
              />
              <TextField
                label="Cta button"
                value={ctaButton}
                onChange={(event) => setCtaButton(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="name-input">Description</InputLabel>

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                textareaName="product Description"
                value={descOne}
                //onEditorChange={(e)=>SetDescription(e.target.value)}
                onEditorChange={(newValue, editor) => setDescOne(newValue)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default NewHomepage;
