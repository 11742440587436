import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../features/user/userSlice";

import "../index.css";

const Login = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const currentUser = useSelector((state) => state.user.info);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = currentUser && currentUser.email;
    if (auth) {
      navigate("/dashboard");
    }
  }, [currentUser]); // Add currentUser to the dependency array

  const handleSubmit = async (e, formRef) => {
    e.preventDefault();
    // const form = document.forms.login
    const obj = {
      email: formRef.current.email.value,
      password: formRef.current.password.value,
    };

    try {
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );

      if (!rawResponse.ok) {
        throw new Error(rawResponse.statusText);
      }

      const content = await rawResponse.json();
      localStorage.setItem("user", JSON.stringify(content.user));
      localStorage.setItem("token", content.token);
      dispatch(setUser(content.user));
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      alert("invalid user name or pasword");
    }
  };

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <a href="#" className="h1">
                <b>FinTech</b>Admin
              </a>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form ref={formRef} onSubmit={(e) => handleSubmit(e, formRef)}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div>
                  {/* /.col */}
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
};

export default Login;
