import React from 'react'

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

import Sidebar from '../../Components/Sidebar'
import Data from './data'

const index = () => {
  return (
      <div className='wrapper'>
          <Header/>
          <Sidebar/>
          <Data/>
          <Footer/>
      </div>
  )
}

export default index
