import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const Intro = () => {
  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-eventspage`
    );
    const result = await response.json();
    setpartner(result);
  };

  useEffect(() => {
    fetchpartnerpage();
  }, []);

  return (
    <section className="blogintrosec">
      <Container>
        <Row className="py-5">
          <Col>
            {partner.map((row, index) => (
              <React.Fragment key={index}>
                <h1 className=" blog-intro-title">{row.heading}</h1>
                <p>{row.subheading}</p>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
