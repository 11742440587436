import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import Intro from "./Intro";
import FlipCards from "../../Dashboard/Components/FlipCards";
import SubscribeForm from "../../Dashboard/Components/SubscribeForm";

const index = () => {
  return (
    <>
      <Helmet>
        <title>Infographics | FinTech Law Firm</title>
        <meta
          name="description"
          content="Follow FinTech Law Firm and our SEC compliance partner Joot's quarterly legal and infographics for industry updates and insights."
        />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <FlipCards
          apiLink={`${process.env.REACT_APP_BACKEND}/api/get-infographicsresources`}
          downloadBaseUrl={`${process.env.REACT_APP_BACKEND}/api/download-infographics`}
        />
        <SubscribeForm />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default index;
