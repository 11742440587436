import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import "../Home/Home.css";
import "../Contact/contact.css";
import "./blogdetail.css";

const Content = () => {
  const params = useParams();

  const [eventstitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState("");

  const getEventDetails = async () => {
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-events/${params.id}`
    );
    result = await result.json();
    setName(result.eventstitle);
    setDesc(result.description);
    setFileName(result.document);
    setDate(
      new Date(parseInt(params.id.substring(0, 8), 16) * 1000).toLocaleString()
    ); // Retrieve date from _id field
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  return (
      <>
        <section className="contactintrosec">
          <Container>
            <Row className="py-5">
              <Col>
                <h1 className=" contact-intro-title">{eventstitle}</h1>
                <p className="text-white text-center">{date}</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="blog-detail-secod-sec">
          <Container>
            <img
                content={'image/jpeg'}
                fetchpriority={'high'}
                loading={'eager'}
                alt={'post-img'}
                width={'100%'}
                src={"https://storage.googleapis.com/fintechlegal/posts/" + fileName}
            />

            <Row>
              <Col className="">
                <p className="blog-date">{date}</p>
                <div className="blog-inner-detail-desc">
                  {ReactHtmlParser(description)}
                </div>

                <Row className="py-5 article-share-icons">
                  <h2 className="py-3">Share this Event</h2>
                  <Col>
                    <FontAwesomeIcon icon={faFacebook} size="3x" className="px-2"/>
                    <FontAwesomeIcon icon={faLinkedin} size="3x" className="px-2"/>
                    <FontAwesomeIcon icon={faTwitter} size="3x" className="px-2"/>
                    <br/>
                    <Link to={"/events"} className="nounderline">
                      <Button>View More Events</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
  );
};

export default Content;
