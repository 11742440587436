import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const Intro = () => {
  return (
      <section className="blogintrosec">
          <Container>
              <Row className='py-5'>
                  <Col>
                      <h1 className=' blog-intro-title'>FinTech Law Blog</h1>
                      <p>FinTech Blog offers a range of resources about the latest developments in <br/> fintech,
                          regtech, digital assets, and regulatory<br/> compliance.</p>
                  </Col>
              </Row>
          </Container>
      </section>
  )
}

export default Intro
