import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Secondsec = () => {
    return (
        <section className='fourth-sec'>
            <Container>
                <Row>
                    <Col>
                        <iframe
                            title={'partnership-form'}
                            src='https://forms.zohopublic.com/codyfoster/form/ComplianceUpdatesFTL/formperma/ra3WF4tQLb1XkKAxk3fkkEgt5A2zN4AqV5Qhp_V3mEo?zf_rszfm=1'
                            width="100%" height="450px"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Secondsec
