import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import "../index.css";
import { request } from "../../helpers/fetch-wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const NewTeam = () => {
  const classes = useStyles();
  const [membername, setName] = useState("");
  const [memberposition, setPosition] = useState("");
  const [memberdesc, setDescription] = useState("");
  const [memberlinkden, setLinkedinUrl] = useState("");
  const [membertwitter, setTwitterUrl] = useState("");
  const [file, setFile] = useState();
  const [displayOnTeamPage, setDisplayOnTeamPage] = useState("yes"); // 'yes' by default

  const [fileName, setFileName] = useState("");
  const [error, SetError] = useState("");

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.info);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleLinkedinUrlChange = (event) => {
    setLinkedinUrl(event.target.value);
  };

  const handleTwitterUrlChange = (event) => {
    setTwitterUrl(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitProduct(); // Call the submitProduct function here
  };
  const handleDisplayChange = (event) => {
    setDisplayOnTeamPage(event.target.value);
  };

  const submitProduct = async () => {
    const userID = currentUser._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-team`;
    const data = {
      membername: membername,
      memberposition: memberposition,
      memberdesc: memberdesc,
      memberlinkden: memberlinkden,
      membertwitter: membertwitter,
      displayOnTeamPage: displayOnTeamPage,
      userID: userID,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("file", file);
    const result = await request({
      method: "post",
      url: baseUrl,
      data: formData,
    });

    if (result) {
      navigate("/team-list");
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add Team Member</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

        <div className="adminform-background px-3 mx-3 py-3">
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Member Name"
                  value={membername}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Member Position"
                  value={memberposition}
                  onChange={handlePositionChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={4}
                  label="Member Description"
                  value={memberdesc}
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="LinkedIn URL"
                  value={memberlinkden}
                  onChange={handleLinkedinUrlChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Twitter URL"
                  value={membertwitter}
                  onChange={handleTwitterUrlChange}
                />
              </Grid>
              <Grid item xs={6}>
                <input
                  accept="image/*"
                  id="thumbnail-upload"
                  type="file"
                  onChange={saveFile}
                />
                <label htmlFor="thumbnail-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload Member Thumbnail
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                <label>Display on team page ?</label>
                <RadioGroup
                  row
                  aria-label="Display on Team Page"
                  name="displayOnTeamPage"
                  value={displayOnTeamPage}
                  onChange={handleDisplayChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewTeam;
