import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import Button from "react-bootstrap/Button";
import { request } from "../../../helpers/fetch-wrapper";

import "../../index.css";

const Data = () => {
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/get-team-with-order`
      );
      const result = await response.json();
      setData(result);

      const orders = result.map((member, index) => ({
        _id: member._id,
        order: member.order !== undefined ? member.order : index,
      }));
      setSortOrder(orders);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteTeam = async (id) => {
    await request({
      url: `${process.env.REACT_APP_BACKEND}/api/delete-team/${id}`,
      method: "delete",
    });
    fetchData();
  };

  const updateOrderMove = async (id, direction) => {
    try {
      const response = await request({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND}/api/team/update-order/${id}/${direction}`,
      });
      setSortOrder(response.data);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleMoveUp = async (currentIndex, memberId) => {
    if (currentIndex > 0 && sortOrder.length > 1) {
      const updatedOrder = [...sortOrder];
      const temp = updatedOrder[currentIndex - 1];
      updatedOrder[currentIndex - 1] = updatedOrder[currentIndex];
      updatedOrder[currentIndex] = temp;
      updatedOrder[currentIndex - 1].order = currentIndex - 1;
      updatedOrder[currentIndex].order = currentIndex;

      setSortOrder(updatedOrder);
      await updateOrderMove(memberId, "up");
      await fetchData();
    }
  };

  const handleMoveDown = async (currentIndex, memberId) => {
    if (currentIndex < sortOrder.length - 1) {
      const updatedOrder = [...sortOrder];
      const temp = updatedOrder[currentIndex + 1];
      updatedOrder[currentIndex + 1] = updatedOrder[currentIndex];
      updatedOrder[currentIndex] = temp;
      updatedOrder[currentIndex + 1].order = currentIndex + 1;
      updatedOrder[currentIndex].order = currentIndex;

      setSortOrder(updatedOrder);
      await updateOrderMove(memberId, "down");
      await fetchData();
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Team Entries</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <Link to="/add-team">
                  <Button variant="primary" size="lg">
                    Add New
                  </Button>
                </Link>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.no #</TableCell>
                <TableCell>Avatar</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Desc</TableCell>
                <TableCell>LinkedIn</TableCell>
                <TableCell>Twitter</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                Array.isArray(sortOrder) && sortOrder.length > 0 ? (
                  sortOrder.map((order, rowIndex) => (
                    <CreateRow
                      data={data}
                      rowIndex={rowIndex}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      deleteTeam={deleteTeam}
                      handleMoveUp={handleMoveUp}
                      handleMoveDown={handleMoveDown}
                      order={order}
                      key={rowIndex}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>No records found.</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No records found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
  );
};

const CreateRow = (props) => {
  const {
    data,
    order,
    rowIndex,
    page,
    rowsPerPage,
    deleteTeam,
    handleMoveDown,
    handleMoveUp,
  } = props;
  const member = data.find((x) => x._id === order._id);
  return (
    <TableRow key={member._id}>
      <TableCell className="teamnameserial-cell">
        {rowIndex + 1 + page * rowsPerPage}
      </TableCell>
      <TableCell>
        <img
            alt={'member-img'}
          className="table-backend-imgs"
          src={
            "https://storage.googleapis.com/fintechlegal/posts/" +
            member.document
          }
        />
      </TableCell>
      <TableCell className="teamname-cell">{member.membername}</TableCell>
      <TableCell className="position-cell">{member.memberposition}</TableCell>
      <TableCell className="description-cell">{member.memberdesc}</TableCell>
      <TableCell className="social-icon-cell">
        {member.memberlinkden ? (
          <a href={member.memberlinkden}>
            <LinkedInIcon />
          </a>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell className="social-icon-cell">
        {member.membertwitter ? (
          <a href={member.membertwitter}>
            <TwitterIcon />
          </a>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell className="action-cell">
        <div className="action-icons">
          <Link to={"/updateteam/" + member._id}>
            <ModeEditOutlineTwoToneIcon />
          </Link>
          <DeleteTwoToneIcon onClick={() => deleteTeam(member._id)} />
          <ArrowUpwardIcon
            onClick={() => handleMoveUp(rowIndex, member._id, order)}
          />
          <ArrowDownwardIcon
            onClick={() => handleMoveDown(rowIndex, member._id, order)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Data;
