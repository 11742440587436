import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Intro from './Intro'
import Secondsec from './Secondsec'
import ThirdSec from './ThirdSec'

const index = () => {
  return (
    <>
    <Helmet>
            <title>
            FinTech Law Services | Securites Law Services
            </title>
            <meta name="description" content="FinTech Law provides dedicated law services to a variety of clients and areas including registered investment advisers, private funds, and startups.
"/>
        </Helmet>
    <Header/>
    <React.Fragment>
    <Intro/>
    <Secondsec/>
    <ThirdSec/>
    </React.Fragment>
    <Footer/>
    </>
    )
}

export default index
