import React from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'

const FourthSec = () => {
    return (
        <section className='fourth-sec'>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email"
                                                      style={{height: '50px'}}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email"
                                                      style={{height: '50px'}}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter your email"
                                                      style={{height: '50px'}}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit" className='my-3  newsletter-btn'>
                                Newsletter Signup
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default FourthSec
