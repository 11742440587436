import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const Thirdsec = () => {
  const [data, setData] = useState([]);
  const [outsource, setOutsource] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-homepage`
    );
    const result = await response.json();
    setData(result);
  };

  const fetchOutsource = async () => {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/get-outsources`
    );
    const result = await response.json();
    setOutsource(result);
  };

  useEffect(() => {
    fetchData();
    fetchOutsource();
  }, []);

  return (
    <section className="third-sec">
      <Container>
        <Row>
          {data.map((row, index) => (
              <React.Fragment key={index}>
                <div className={'title secondary centered'}>{row.buttontwo}</div>
                <p>{row.descone}</p>
              </React.Fragment>
          ))}

          {outsource.map((row, index) => (
            <Col md={4} lg={3} xs={12} key={index}>
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h3>{row.outsourcenumber}</h3>
                  </div>
                  <div className="flip-card-back">
                    <div className="flip-card-back-bg">
                      <h1>{row.outsourcetitle}</h1>
                      <div>{ReactHtmlParser(row.outsourcedesc)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Thirdsec;
