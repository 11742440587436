import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap';

import '../Home/Home.css'
import '../Contact/contact.css'
import ReactHtmlParser from "react-html-parser";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";

const styles = {
    width: "100%",
    maxHeight: "450px",
    objectFit: "cover",
};

const Content = () => {
    const params = useParams();
    const {slug} = useParams();


    const [description, setDesc] = useState("");
    const [metatitle, setMetatitle] = useState("");
    const [metadescription, setMetadescription] = useState("");
    const [fileName, setFileName] = useState("");
    const [date, setDate] = useState("");
    const [posttitle, setName] = useState('');
    const [data, setData] = useState([]);

    const getBlogDetails = async () => {
        let result = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/update-blog/${params.slug}`
        );
        result = await result.json();
        setName(result.posttitle);
        setDesc(result.description);
        setFileName(result.document);
        setMetatitle(result.metatitle);
        setMetadescription(result.metadescription);
        setDate(result.date);
    };

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-posts`);
        const result = await response.json();
        const post = result.find(post => post.slug === slug); // find the current post based on the slug
        setData([{...post}]); // set the data for the current post with the selectedTeam details
        setMetatitle(post.metatitle); // set the meta title for the current post
        setMetadescription(post.metadescription); // set the meta description for the current post
        setDate(
            new Date(parseInt(post._id.substring(0, 8), 16) * 1000).toLocaleString()
        ); // set the date for the current post
    };

    useEffect(() => {
        getBlogDetails();
        fetchData();
    }, []);

    return (
        <>
            <head>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription}/>
                <meta property="og:title" content={metatitle}/>
                <meta property="og:description" content={metadescription}/>
                {fileName && (
                    <meta
                        property="og:image"
                        content={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
                    />
                )}
            </head>

            <section className="contactintrosec">
                <Container>
                    <Row className='py-5'>
                        <Col>

                            <h1 className=' contact-intro-title'>{posttitle}</h1>
                            {data.map((row, index) => (
                                <React.Fragment key={index}>
                                    {row.selectedTeam && (
                                        <>
                                            <p className='blog-date text-center'>{row.selectedTeam.membername}-{new Date(row.date).toLocaleDateString()}</p>

                                        </>
                                    )}

                                    {!row.selectedTeam && (
                                        <>
                                            <h6 className='blogpage-author'>Author Name</h6>
                                            <p className='blogpage-author-position'>Author Position</p>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="blog-detail-secod-sec">
                <Container>
                    <title>{metatitle}</title>
                    <meta name="description" content={metadescription}/>
                    {fileName && (
                        <img
                            style={styles}
                            src={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
                            alt={metatitle}
                        />
                    )}

                    <Row>
                        <Col className="">
                            <p className="blog-date">{date}</p>
                            <div className="blog-inner-detail-desc">
                                {ReactHtmlParser(description)}
                            </div>

                            <Row className="py-5 article-share-icons">
                                <h2 className="py-3">Share this Article</h2>
                                <Col className="article-icons-row" xs={5}>
                                    <div>
                                        <FacebookShareButton url={window.location.href}>
                                            <FontAwesomeIcon
                                                icon={faFacebook}
                                                size="3x"
                                                className="px-2"
                                            />
                                        </FacebookShareButton>
                                        <LinkedinShareButton url={window.location.href}>
                                            <FontAwesomeIcon
                                                icon={faLinkedin}
                                                size="3x"
                                                className="px-2"
                                            />
                                        </LinkedinShareButton>
                                        <TwitterShareButton url={window.location.href}>
                                            <FontAwesomeIcon
                                                icon={faTwitter}
                                                size="3x"
                                                className="px-2"
                                            />
                                        </TwitterShareButton>
                                    </div>
                                </Col>
                                <Link to={"/blog"}>
                                    <Button>View More Posts</Button>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Content
