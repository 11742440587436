import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Intro from './Intro'
import Secondsec from './Secondsec'

const index = () => {
  return (
    <>
    <Header/>
    <React.Fragment>
        <Intro/>
        <Secondsec/>
    </React.Fragment>
    <Footer/>
    </>
    )
}

export default index
