import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import Fre from '../../assets/featured-resource-eq.webp'

const Thirdsec = () => {
    return (
        <>
            <section className='featured-resource-sec'>
                <Container>
                    <Row>
                        <Col md={7}>
                            <div className={'title secondary'}>
                                Featured Resource
                            </div>
                            <p>
                                Need help navigating the new SEC Derivatives Rule? We can<br/> help you implement an
                                effective derivatives risk management<br/> program and maintain compliance.
                            </p>
                            <Link to={'/blog'}>
                                <Button>Read More</Button></Link>
                        </Col>
                        <Col md={5}>
                            <img src={Fre} alt={'featured-resource-img'} width={'100%'}/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='fourth-sec'>
                <Container>
                    <Row>
                        <Col>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="email" placeholder="Enter your email"
                                                          style={{height: '50px'}}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="email" placeholder="Enter your email"
                                                          style={{height: '50px'}}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter your email"
                                                          style={{height: '50px'}}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" className='my-3 newsletter-btn'>
                                    Newsletter Signup
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Thirdsec
