import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Col, Container, Row } from 'react-bootstrap'

import './eventdetail.css'
import '../ReFunds/ReFunds.css'

const Content = () => {
    const params = useParams();

    const [eventstitle, setName] = useState('');
    const [description, setDesc] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [youtubevideolink, setyoutubevideolink] = useState('');

    const getWebinarDetails = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-webinars/${params.id}`);
        result = await result.json();
        setName(result.eventstitle);
        setDesc(result.description);
        setExcerpt(result.excerpt);
        setyoutubevideolink(result.youtubevideolink)
    };

    useEffect(() => {
        getWebinarDetails();
    }, []);

    return (
        <>
            <section className='event-detail-sec-1'>
                <Container fluid>
                    <Row>
                        <Col md={3}>
                        </Col>
                        <Col>
                            <h2 className='text-center'>{eventstitle}</h2>
                            <p className='sub-head-event-detail text-center'>{excerpt}</p>

                            <div className='text-center'>
                                {ReactHtmlParser(description)}
                            </div>
                        </Col>
                        <Col md={3}>
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='refunds-service-sec-1'>
                <Container fluid>
                    <Row>
                        <Col>
                            <iframe width="400" height="300" src={youtubevideolink} title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen>

                            </iframe>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Content
