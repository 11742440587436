import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Card, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';

import blog from '../../assets/resources-blog-image.webp'
import blog2 from '../../assets/resources-events-image.webp'

const Secondsec = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
    };

    useEffect(() => {
        fetchpartnerpage();
    }, []);

    return (
        <>
            <section className='resources-sec-1'>
                <Container>
                    {partner.map((row, index) => (
                        <React.Fragment key={index}>
                            <p>{row.ctatitleheading}</p>
                        </React.Fragment>
                    ))}
                    <Row>
                    <Col md={2}>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={blog}/>
                            </Card>
                            <Link to={'/blog'}>
                                <Button variant="primary">FinTech Law Blog</Button></Link>

                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={blog2}/>
                            </Card>
                            <Link to={'/events'}>
                                <Button variant="primary">Events</Button></Link>

                        </Col>
                        <Col md={2}>
                        </Col>

                    </Row>
                </Container>
            </section>


            <section className='fourth-sec'>
                <Container>
                    <Row>
                        <Col md={8}>
                            <p>We are experts in private and registered funds, SEC, FINRA<br/> regulations, and
                                everything in between. Connect with us today.
                            </p>
                        </Col>
                        <Col><Button>Contact FinTech Law</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    )
}

export default Secondsec
