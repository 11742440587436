import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Grid,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@mui/material";

import "../index.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { request } from "../../helpers/fetch-wrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const CircularIndeterminate = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loader}>
      <CircularProgress />
    </Box>
  );
};

const NewBlog = () => {
  const classes = useStyles();
  const [posttitle, setName] = useState("");
  const [description, setDesc] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [tags, setTags] = useState([]);
  const [slug, setSlug] = useState("");
  const [category, setCategory] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.info);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-team`
    );
    const result = await response.json();
    setTeams(result);
  };

  const fetchCategories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-categories`
    );
    const result = await response.json();
    setCategories(result);
  };
  useEffect(() => {
    fetchData();
    fetchCategories();
  }, []);

  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handleExcerptChange = (event) => {
    setExcerpt(event.target.value);
  };

  const handleTagChange = (newTags) => {
    setTags(newTags);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please upload an image.");
      return;
    } else if (!description) {
      setError("Please enter description.");
      return;
    }

    setLoading(true); // Set loading to true when form is submitted
    try {
      await submitProduct(); // Call the submitProduct function here

      // Redirect to the posts-list page after successful submission
      navigate("/post-list");
    } catch (error) {
      // Handle error if submission fails
      console.error("Submission error:", error);
    } finally {
      setLoading(false); // Set loading to false after submission (success or failure)
    }
  };

  const submitProduct = async () => {
    const userID = currentUser._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-posts`;
    const data = {
      posttitle: posttitle,
      description: description,
      excerpt: excerpt,
      tags: tags,
      slug: slug,
      metatitle: metatitle,
      metadescription: metadescription,
      date: date,
      selectedTeam: selectedTeam,
      userID: userID,
      category: category,
    };

    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append("file", file);
    setError("");
    const result = await request({
      method: "post",
      url: baseUrl,
      data: formData,
    });

    if (result) {
      console.log(result);
      navigate("/post-list");
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  return (
    <>
      {loading ? (
        <CircularIndeterminate /> // Show loader while loading is true
      ) : (
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Add New Post</h1>
                </div>
                {/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right"></ol>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          <div className="adminform-background px-3 mx-3 py-3">
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Post Title"
                    value={posttitle}
                    onChange={handleNameChange}
                  />
                  <TextField
                    required
                    label="Post slug"
                    value={slug}
                    onChange={handleSlugChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Post Excerpt"
                    value={excerpt}
                    onChange={handleExcerptChange}
                  />
                  <Autocomplete
                    multiple
                    options={categories}
                    getOptionLabel={(option) => option}
                    value={category}
                    onChange={(event, newValue) => {
                      setCategory(newValue.map((val) => val.toUpperCase()));
                    }}
                    onBlur={(event) => {
                      const newCategory = event.target.value
                        .trim()
                        .toUpperCase();
                      if (newCategory && !category.includes(newCategory)) {
                        setCategory((prev) => [...prev, newCategory]);
                      }
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Post Category" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="name-input" required>
                    Post Description
                  </InputLabel>

                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    textareaName="product Description"
                    value={description}
                    //onEditorChange={(e)=>SetDescription(e.target.value)}
                    onEditorChange={(newValue, editor) => setDesc(newValue)}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins:
                        "autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help | anchor",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel htmlFor="name-input">Add Seo Tags</InputLabel>
                  <TagsInput value={tags} onChange={handleTagChange} />
                </Grid>
                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    id="thumbnail-upload"
                    type="file"
                    onChange={saveFile}
                    style={{ display: "none" }}
                  />
                  {fileName && (
                    <div className="img-box-dashboard-featured">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Selected file preview"
                      />
                    </div>
                  )}
                  <label htmlFor="thumbnail-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Resource
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="Meta Title"
                    value={metatitle}
                    onChange={(e) => setMetatitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="Meta Description"
                    value={metadescription}
                    onChange={(e) => setMetadescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel id="team-select-label" required>
                    Select Author
                  </InputLabel>
                  <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={selectedTeam}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  >
                    {teams.map((team) => (
                      <MenuItem key={team._id} value={team._id}>
                        <img
                          className="table-backend-img-author"
                          src={
                            "https://storage.googleapis.com/fintechlegal/posts/" +
                            team.document
                          }
                        />{" "}
                        - {team.membername}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    type="date"
                    label="Date"
                    required
                    value={date.toISOString().substr(0, 10)}
                    onChange={(event) => setDate(new Date(event.target.value))}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error" onClose={() => setError("")}>
                      {error}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default NewBlog;
