import React, { useState } from "react";
import { Col, Container, Row, Card, Modal } from "react-bootstrap";

const Slideshow = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    {
      image:
        "https://storage.googleapis.com/fintechlegal/posts/RIA%20to%20RIC.PNG",
      title: "RIA to RIC",
    },
    {
      image:
        "https://storage.googleapis.com/fintechlegal/posts/Startup%20query.PNG",
      title: "Startup query",
    },
    {
      image:
        "https://storage.googleapis.com/fintechlegal/posts/State%20RIA%20query.PNG",
      title: "State RIA query",
    },
  ];

  const openImage = (index) => {
    setSelectedImage(images[index]);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <section className="blog-sec">
      <Container>
        <Row className="py-3" style={{ minHeight: "400px" }}>
          <Col md={3}>
            <h1>How It Works</h1>
            <p>
              Explore real-world scenarios and witness the assistant in action.
              Dive into our interactive examples and experience seamless legal
              guidance at your fingertips.{" "}
            </p>
          </Col>
          {images.slice(0, 3).map((row, index) => (
            <Col md={3} className="example-images" key={index}>
              <Card
                className="blog-box"
                style={{ minHeight: "400px" }}
                onClick={() => openImage(index)}
              >
                <Card.Img
                  variant="top"
                  src={row.image}
                  className="card-image"
                  style={{ minHeight: "400px", border: "1 px solid #ab0f53" }}
                />
                <Card.Body className="blog-body">
                  <Card.Title className="blog-title">{row.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Modal
        show={!!selectedImage}
        onHide={closeImage}
        centered
        dialogClassName="custom-modal"
        style={{
          margin: "auto",
          display: "block",
        }}
      >
        {selectedImage && (
          <>
            <span className="close-button" onClick={closeImage}>
              &times;
            </span>
            <img
              src={selectedImage.image}
              alt={selectedImage.title}
              className="img-fluid"
              style={{
                width: "auto",
                height: "auto",
                objectFit: "contain",
                margin: "auto",
                display: "block",
                background: "black",
              }}
            />
          </>
        )}
      </Modal>
    </section>
  );
};

export default Slideshow;
