import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../Home/Home.css'
import './contact.css'

const Intro = () => {
  return (
      <section className="contactintrosec">
          <Container>
              <Row className='py-5'>
                  <Col>

                      <h1 className=' contact-intro-title'>Terms of Service</h1>
                  </Col>

              </Row>
          </Container>
      </section>
  )
}

export default Intro
