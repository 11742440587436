import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';

import Team1 from '../../assets/Bob.png'
import Team2 from "../../assets/framedimg_2.png";


const Fifthsec = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-homepage`);
        const result = await response.json();
        setData(result);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
      <section className="fifth-sec">
        <Container>
          {data.map((row, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md={4}>
                  <img
                    src={Team1}
                    width={"100%"}
                    alt={"bob-img"}
                    fetchpriority="low"
                    loading="lazy"
                  />
                </Col>
                <Col md={8}>
                  <div className={"title primary"}>{row.headingthree}</div>
                  <p>{row.ctatitleheading}</p>
                </Col>
              </Row>

              <Row className="py-5">
                <Col md={8}>
                  <div className={"title primary"}>{row.ctadesc}</div>
                  <p>{row.ctabutton}</p>
                </Col>
                <Col md={4}>
                  <img
                    className="home-sec-Team2img"
                    src={Team2}
                    alt={"cfo"}
                    width={"100%"}
                    fetchpriority="low"
                    loading="lazy"
                  />
                </Col>
              </Row>
            </React.Fragment>
          ))}

          <Link to={"/contact"} className="nounderline">
            <Button
              variant="outline-light my-5"
              className="blue-btn home-contact-btn"
            >
              Contact FinTech Law
            </Button>
          </Link>
        </Container>
      </section>
    );
}

export default Fifthsec;
