import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams , Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import img from "../../assets/services-private-funds-small-border.webp";
import Newsleter from "../../assets/newsletter-signup-bg.webp";

const Thirdsec = () => {
  const [packagedesc, setDesc] = useState("");
  const [packagesubdescone, setPackagesubdescone] = useState("");
  const [packagedesctitle, setPackagedesctitle] = useState("");
  const [packagesubdesctwo, setPackagesubdesctwo] = useState("");
  const [packagesubdescthree, setPackagesubdescthree] = useState("");
  const [buttontext, setButtontext] = useState("");
  const [buttonlink, setButtonlink] = useState("");
  const [fileName, setFileName] = useState("");
  const params = useParams();

  const getTeamDetails = async () => {
    console.warn(params);
    let result = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/update-package/${params.slug}`
    );
    result = await result.json();

    setDesc(result.packagedesc);
    setPackagesubdescone(result.packagesubdescone);
    setPackagedesctitle(result.packagedesctitle);
    setPackagesubdesctwo(result.packagesubdesctwo);
    setPackagesubdescthree(result.packagesubdescthree);
    setButtontext(result.buttontext);
    setButtonlink(result.buttonlink);
    setFileName(result.document);
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  return (
    <section className="refunds-service-sec-3">
      <Container>
        <Row>
          <p className="py-5 text-center services-detailed-desc">
            {packagesubdescone}
          </p>
          <Col md={6} className="">
            <>
              <div className={'title secondary pt-0 mb-3'}>{packagedesctitle}</div>
              <div className="">{ReactHtmlParser(packagedesc)}</div>
              <Link className="nounderline" to={buttonlink}>
                <Button
                  variant="outline-light my-5"
                  className="blue-btn dynamic-detailed-btn"
                >
                  {buttontext}
                </Button>
              </Link>
            </>
          </Col>
          <Col md={6} className="py-5">
            {fileName && (
              <img
                  fetchpriority={'low'}
                  loading={'lazy'}
                 alt={'post-img'}
                className="about-intro-img"
                src={`https://storage.googleapis.com/fintechlegal/posts/${fileName}`}
                width={'100%'}
              />
            )}
          </Col>
        </Row>
      </Container>
      <p className="py-3 text-center services-detailed-desc">
        {packagesubdesctwo}
      </p>
      <img  width={'100%'} src={Newsleter} alt={'newsletter-img'} fetchpriority={'low'} loading={'lazy'} />
      <p className="py-3 text-center services-detailed-desc">
        {packagesubdescthree}
      </p>
      <Link to={"/contact"} className="nounderline">
        <Button
          variant="outline-light my-5"
          className="blue-btn res-detailed-btn"
        >
          Talk with FinTech Law
        </Button>
      </Link>
    </section>
  );
};

export default Thirdsec;
