import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import Data from './Data'

import "../index.css";

const index = () => {
  return (
      <div className='wrapper'>
        <Header/>
        <Sidebar/>
        <Data/>
        <Footer/>
      </div>
  )
}

export default index
