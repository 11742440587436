import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../Services/services.css";

const Intro = () => {
  const [partner, setpartner] = useState([]);

  const fetchpartnerpage = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/get-aboutpage`
    );
    const result = await response.json();
    setpartner(result);
  };
  useEffect(() => {
    fetchpartnerpage();
  }, []);

  return (
    <section className="blogintrosec">
      <Container>
        <Row className="py-5">
          <Col>
            {partner.map((row, index) => (
              <React.Fragment key={index}>
                <h1 className="about-head-top">{row.heading}</h1>
                <h2 className="py-3 about-p-top">{row.subheading}</h2>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
