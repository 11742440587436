import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import SubscribeForm from "../../Dashboard/Components/SubscribeForm";

const ThirdSec = () => {
  const disclaimerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe disclaimer text
    observer.observe(disclaimerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section>
      <SubscribeForm />
      <div className="thirdsec-text">
        <Container>
          <Row>
            <Col>
              <div className="disclaimer-text" ref={disclaimerRef}>
                <p>
                  **The FinTech Law Assistant provides information, not legal
                  advice; you should consult with a legal professional on any
                  legal matters. Given the complexities of securities laws and
                  the potential consequences of non-compliance, it is highly
                  recommended that you consult with a legal professional
                  specialized in securities law to ensure that your funding
                  round is compliant with all relevat regulations. This
                  information is not legal advice but is intended to provide a
                  general overview based on the knoweldge avialable up to April
                  2023. For specific advice tailored to your situation, please
                  consult legal advisor.**
                </p>
                <p>
                  **Please note that access to the tool requires a ChatGPT Pro
                  or Team license.**
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ThirdSec;
