import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import Blogsec from "./Blogsec";
import Fifthsec from "./Fifthsec";
import Fourthsec from "./Fourthsec";
import Intro from "./Intro";
import Secondsec from "./Secondsec";
import Thirdsec from "./Thirdsec";
import SubscribeForm from "../../Dashboard/Components/SubscribeForm";

import './Home.css'

const index = () => {
  return (
    <>
      <Helmet>
        <title>Securities Law Firm | FinTech Law | Securities Lawyer</title>
        <meta
          name="description"
          content="FinTech Law is an experienced securities law firm providing legal services to clients throughout the financial and fintech industries."
        />
      </Helmet>
      <Header />
      <React.Fragment>
        <Intro />
        <Secondsec />
        <Thirdsec />
        <Fourthsec />
        <Blogsec />
        <Fifthsec />
        <SubscribeForm />
      </React.Fragment>
      <Footer />
    </>
  );
};

export default index;
