import React from 'react'
import Login from './Login'

const index = () => {
  return (
      <div className='wrapper'>
          <Login/>
      </div>
  )
}

export default index
