import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./flipcards.css";

const FlipCards = ({ apiLink, downloadBaseUrl }) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(apiLink);
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiLink]);

  return (
    <section className="news-letter-resource-second-sec">
      <Container>
        <Row className="py-5">
          {Array.isArray(data) && data.length > 0 ? (
            data
              .slice()
              .reverse()
              .map((row, index) => (
                <Col md={4} lg={3} xs={12} key={index}>
                  <div className="flip-cards">
                    <div className="flip-card-inners">
                      <div className="flip-card-front">
                        <h3 className="newsletter-resource-box-title">
                          {row.name}
                        </h3>
                        <Link
                          className="download-resource-link"
                          to={`${downloadBaseUrl}/${encodeURIComponent(
                            row.fileId
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"Download Issue"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
          ) : (
            <p>No Record Found</p>
          )}
          <Link className="nounderline" to="/contact">
            <Button>Contact FinTech Law Today!</Button>
          </Link>
        </Row>
      </Container>
    </section>
  );
};

export default FlipCards;
