import React ,{useState,useEffect}from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'

import './ReFunds.css'

const Intro = () => {
  const [packagetitle, setHeading] = useState("");
  const [packageexcerpt, setPackageexcerpt] = useState("");
  const params = useParams();

  const getTeamDetails = async () => {
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/update-package/${params.slug}`);
    result = await result.json();
    setHeading(result.packagetitle);
    setPackageexcerpt(result.packageexcerpt);
  };

  useEffect(() => {
    getTeamDetails();
  }, []);

  return (
    <section className="blogintrosec">
        <Container >
          <Row className='py-5'>
            <Col>
              <>
                  <h2 className='inner-service-head'>{packagetitle}</h2>
                  <h1 className='inner-service-subhead'>{packageexcerpt}</h1>
              </>
            </Col>
          </Row>
        </Container>
      </section>
    )
}

export default Intro
