import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Intro from './Intro'
import Secondsec from './Secondsec'

const index = () => {
  return (
    <>
    <Helmet>
    <title>
    Contact FinTech Law Firm | Securities Lawyers
            </title>
            <meta name="description" content="Contact FinTech Law Firm for assistance in securities law, startup law, and a variety of other services.
"/>
    </Helmet>
    <Header/>
    <React.Fragment>
        <Intro/>
        <Secondsec/>
    </React.Fragment>
    <Footer/>
    </>
    )
}

export default index
