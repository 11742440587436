import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const Secondsec = () => {
    return (
        <>
            <section className="second-sec-contact">
                <Container>
                    <Row>
                        <Col className="contact-first-col">
                            <h2>Terms of Service&nbsp;</h2>
                            <p>
                                <strong>Effective Date: January 1, 2022&nbsp;</strong>
                            </p>
                            <p>
                                Welcome to FinTech Law’s service and operations website. This
                                website is maintained as a service to our customers. By using
                                this website, you agree to comply with and be bound by the
                                following terms and conditions of use. Please review these terms
                                and conditions carefully. If you do not agree to these terms and
                                conditions, please do not use this website.&nbsp;
                            </p>
                            <p>
                                FinTech Law, LLC (“FinTech Law,” “FinTech,” “FTL,” “we,” “us,”
                                or “our”) offers legal services to a variety of clients
                                (collectively, the “Services”), and may publish content related
                                to such legal services on fintechlegal.io (the “Website”).&nbsp;
                            </p>
                            <p>
                                Users of the Website are called “Site Visitors,” “Users,” or
                                “you,” for purposes of these Terms of Service (the “Terms”).
                                Regardless of what type of User you are, these Terms create a
                                legal agreement directly between you and FTL and explain the
                                rules governing use of the Website. You acknowledge and agree
                                that you have read, understand, and agree to be bound by these
                                Terms and our Privacy Policy. If you do not agree to these
                                Terms, please do not access or use the Website. While the Terms
                                govern your use of the Website, such use does not create an
                                attorney-client relationship nor does it entitle you to receive
                                Services from FTL. If you wish to enter into an attorney-client
                                relationship with FTL, you will need to complete our client
                                intake form and be accepted by FTL as a client, at which time
                                you will need to sign an engagement letter and pay a retainer to
                                FTL. Until these steps are completed, no attorney-client
                                relationship exists between you and FTL. &nbsp;
                            </p>
                            <p>
                                IMPORTANT NOTICE: DISPUTES ABOUT THESE TERMS AND THE WEBSITE
                                PROVIDED BY FINTECH LAW ARE SUBJECT TO BINDING ARBITRATION AND A
                                WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN THE “MANDATORY
                                ARBITRATION AND CLASS ACTION WAIVER” SECTION BELOW.&nbsp;
                            </p>
                            <p>
                                We can modify these Terms at our sole discretion. Please check
                                this page periodically for updates. If you do not agree to, or
                                cannot comply with, the modified Terms, you must stop using the
                                Website. The updated Terms will take effect upon their posting
                                and will apply on a going-forward basis, unless otherwise
                                provided in a notice to you, and except as provided in the
                                Mandatory Arbitration and Class Action Waiver section of these
                                Terms. Your continued use of the Website after any such update
                                constitutes your acceptance of such changes.
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        <strong>Agreement.</strong> This Terms of Service agreement
                                        (the “Agreement”) specifies the Terms and Conditions for
                                        access to and use of the Website and its content. This
                                        Agreement may be modified at any time by FinTech upon
                                        posting of the modified Agreement. Any such modifications
                                        shall be effective immediately. You can view the most recent
                                        version of these terms at any time at{" "}
                                        <a href="https://fintechlegal.io/terms-of-service/">
                                            <u>Terms Of Service | FinTech Law (fintechlegal.io)</u>
                                        </a>
                                        . Each use by you shall constitute and be deemed your
                                        unconditional acceptance of this Agreement. By using the
                                        Websites you become a user (“User”) and you agree to be
                                        bound by all the Terms set forth in this Agreement as long
                                        as you remain a User. IF YOU DO NOT AGREE TO THE TERMS,
                                        PLEASE DO NOT USE THIS WEBSITE.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Eligibility and Scope.</strong>&nbsp;
                                    </p>
                                    <ol>
                                        <li>
                                            <p>
                                                <strong>General.</strong> To use the Website you must
                                                be, and represent and warrant that you are, at least 13
                                                years of age and competent to agree to these Terms. If
                                                FTL has previously prohibited you from accessing or
                                                using the Website, you are not permitted to access or
                                                use it.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Location.</strong> These Terms are applicable to
                                                Users located in the United States only. The Website is
                                                not intended for users outside the United States. &nbsp;
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>
                                        <strong>Our Proprietary Rights.</strong> The Website is
                                        owned and operated by FinTech Law and contains materials
                                        (including all software, design, text, editorial materials,
                                        informational text, photographs, illustrations, audio clips,
                                        video clips, artwork and other graphic materials, and names,
                                        logos, trademarks and services marks) that are derived in
                                        whole or in part from materials supplied by FTL and its
                                        partners, as well as other sources, and are protected by
                                        U.S. copyright laws, international treaty provisions,
                                        trademarks, service marks and other intellectual property
                                        laws. The Website is also protected as a collective work or
                                        compilation under U.S. copyright and other law and treaties.
                                        You agree to abide by all applicable copyright and other
                                        laws, as well as any additional copyright notices or
                                        restrictions contained in the Website. You acknowledge that
                                        the Website was developed, compiled, prepared, revised,
                                        selected, and arranged by FinTech Law and others through the
                                        application of methods and standards of judgment developed
                                        and applied through the expenditure of substantial time,
                                        effort, and money and constitute valuable intellectual
                                        property of FinTech Law and such others. You agree to
                                        protect the proprietary rights of FinTech Law and all others
                                        having rights in the Website during and after the term of
                                        these Terms and to comply with all written requests made by
                                        FinTech Law or its suppliers and licensors (collectively,
                                        “Suppliers”) of content or otherwise to protect their and
                                        others’ contractual, statutory, and common law rights in the
                                        Website. You agree to notify FinTech Law immediately upon
                                        becoming aware of any claim that the Website infringes upon
                                        any copyright, trademark, or other contractual, statutory,
                                        or common law rights. All present and future rights in and
                                        to trade secrets, patents, copyrights, trademarks, service
                                        marks, know-how, and other proprietary rights of any type
                                        under the laws of any governmental authority, domestic or
                                        foreign, including without limitation rights in and to all
                                        applications and registrations relating to the Website
                                        shall, as between you and FinTech Law, always be and remain
                                        the sole and exclusive property of FinTech Law. Any
                                        unauthorized use of any material contained on or through the
                                        Website may violate copyright laws, trademark laws, the laws
                                        of privacy and publicity and communications regulations and
                                        statutes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>User Content and Feedback.</strong>&nbsp;
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <strong>Feedback on the Website.</strong> The Website
                                                may have certain features that allow you to submit
                                                comments, information, and other materials
                                                (collectively, “Feedback”) to FinTech Law and share such
                                                Feedback with other users, or the public. By submitting
                                                Feedback through the Website, you grant FinTech Law a
                                                license to access, use, copy, reproduce, process, adapt,
                                                publish, transmit, host, and display that Feedback for
                                                any purpose (including in testimonials or other FinTech
                                                Law marketing materials and where required to do so by
                                                law or in good faith to comply with legal process.). We
                                                reserve the right to remove any Feedback posted in
                                                public forums for any reason at our sole
                                                discretion.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>
                                                    User Content and Feedback Representations.
                                                </strong>
                                                You acknowledge and agree that you have all required
                                                rights to submit User Content and Feedback without
                                                violation of any third-party rights. You understand that
                                                FTL does not control, and is not responsible for, User
                                                Content or Feedback, and that by using the Website, you
                                                may be exposed to User Content or Feedback from other
                                                users that is offensive, indecent, inaccurate,
                                                misleading, or otherwise objectionable. Please also note
                                                that User Content and Feedback may contain typographical
                                                errors, other inadvertent errors, or inaccuracies. You
                                                agree that you will indemnify, defend, and hold harmless
                                                FinTech Law for all claims resulting from User Content
                                                or Feedback you submit through the Website. We reserve
                                                the right, at our own expense, to assume the exclusive
                                                defense and control of such disputes, and you will
                                                cooperate with us in asserting any available defenses.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>
                                        <strong>Acceptable Use.</strong>&nbsp;
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <strong>Acceptable Use.</strong> All Users must comply
                                                with the following rules regarding acceptable use of the
                                                Website. &nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Disruption of the Service.</strong> You may
                                                not:&nbsp;
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>
                                                        access, tamper with, or use non-public areas of the
                                                        Website, FinTech’s computer systems, or the
                                                        technical delivery systems of FinTech’s
                                                        providers;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        probe, scan, or test the vulnerability of any system
                                                        or network or breach or circumvent any security or
                                                        authentication measure;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        access or search the Website by any means other than
                                                        FTL’s publicly supported interfaces (for example,
                                                        “scraping”);&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        attempt to disrupt or overwhelm our infrastructure
                                                        by intentionally imposing unreasonable requests or
                                                        burdens on our resources (<i>e.g.</i>, using “bots”
                                                        or other automated systems to send requests to our
                                                        servers at a rate beyond what could be sent by a
                                                        human user during the same period); or&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        interfere with or disrupt the access of any user,
                                                        host, or network, including, without limitation, by
                                                        sending a virus, overloading, flooding, spamming,
                                                        mail-bombing the Website, or by scripting the
                                                        creation of User Content in such a manner as to
                                                        interfere with or create an undue burden on the
                                                        Website.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Misuse of the Website.</strong> You may not
                                                utilize the Website to conduct, promote or
                                                support:&nbsp;
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>any unlawful or fraudulent activities;&nbsp;</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        the impersonation of another person or entity or the
                                                        misrepresentation of an affiliation with a person or
                                                        entity in a manner that does or is intended to
                                                        mislead, confuse, or deceive others;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        activities that are defamatory, libelous, or
                                                        threatening, constitute hate speech, harassment, or
                                                        stalking;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        the publishing or posting of other people’s private
                                                        or personal information without their express
                                                        authorization and permission;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        the sending of unsolicited communications,
                                                        promotions advertisements, or spam;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        the publishing of or linking to malicious content
                                                        intended to damage or disrupt another user’s browser
                                                        or computer; or&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        the promotion or advertisement of products or
                                                        services other than your own without appropriate
                                                        authorization.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>
                                                    User Content Standards within the Website.
                                                </strong>
                                                You may not post any User Content on the Website
                                                that:&nbsp;
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>
                                                        violates any applicable law, any third party’s
                                                        intellectual property rights, or anyone’s right of
                                                        privacy or publicity;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        is deceptive, fraudulent, illegal, obscene,
                                                        pornographic (including child pornography, which,
                                                        upon becoming aware of, we will remove and report to
                                                        law enforcement, including the National Center for
                                                        Missing and Exploited children), defamatory,
                                                        libelous, or threatening, constitutes hate speech,
                                                        harassment, or stalking;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        contains any personal information of minors;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        contains any sensitive personal information, such as
                                                        financial information, payment card numbers, social
                                                        security numbers, or health information without
                                                        FinTech’s prior written consent granted as part of a
                                                        Services engagement;&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        contains viruses, bots, worms, or similar harmful
                                                        materials; or&nbsp;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        contains any information that you do not have a
                                                        right to make available under law or any contractual
                                                        or fiduciary duty.
                                                    </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Violations.</strong> In addition to any other
                                                remedies that may be available to us, FinTech Law
                                                reserves the right to take any remedial action it deems
                                                necessary, including immediately suspending or
                                                terminating your account or your access to the Website,
                                                upon notice and without liability for FinTech Law should
                                                you fail to abide by the rules in this Section 6 or if,
                                                in FinTech’s sole discretion, such action is necessary
                                                to prevent disruption of the Website for other users.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>
                                        <strong>Privacy.</strong> Your visit to our Website is also
                                        governed by our Privacy Policy. Please review our Privacy
                                        Policy at
                                        <a href="https://joot.io/privacy">
                                            <u>fintechlaw.io/privacy</u>
                                        </a>
                                        .&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Intended Audience.</strong> The Website is intended
                                        for FinTech Law clients and prospective clients only and
                                        their authorized persons.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Compliance with Laws.</strong> You agree to comply
                                        with all applicable laws regarding your use of the Website.
                                        You further agreed that information provided by you is
                                        truthful and accurate to the best of your knowledge.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Do Not Rely on the Website.</strong> Opinions,
                                        advice, statements, or other comments should not necessarily
                                        be relied upon and are not to be construed as professional
                                        advice, including legal advice, from FinTech Law, which does
                                        not guarantee the accuracy or completeness of any of the
                                        information provided, and is not responsible for any loss
                                        resulting from your reliance on such information. If you
                                        seek legal advice from FinTech Law, you must first enter
                                        into a client engagement with us.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Third-Party Links and Website.</strong> The Website
                                        may provide (1) information and content provided by third
                                        parties; (2) links to third-party websites or resources,
                                        such as sellers of goods and services; and (3) third-party
                                        products and services for sale directly to you. FinTech Law
                                        is not responsible for the availability of such external
                                        sites or resources, and does not endorse and is not
                                        responsible or liable for (i) any content, advertising,
                                        products, or other materials on or available from such sites
                                        or resources, (ii) any errors or omissions in these websites
                                        or resources, or (iii) any information handling practices or
                                        other business practices of the operators of such sites or
                                        resources. You further acknowledge and agree that FinTech
                                        Law shall not be responsible or liable, directly or
                                        indirectly, for any damage or loss caused or alleged to be
                                        caused by or in connection with use of or reliance on any
                                        linked sites or resources. Your interactions with such third
                                        parties will be governed by the third parties’ own terms of
                                        service and privacy policies, and any other similar
                                        terms.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Indemnification.</strong> You agree to indemnify,
                                        defend, and hold FinTech Law and our partners, employees,
                                        and affiliates, harmless from any liability, loss, claim and
                                        expense, including reasonable attorney’s fees, related to
                                        your violation of this Agreement or use of the
                                        Website.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Disclaimer; No Warranties.</strong> THE INFORMATION
                                        ON THE WEBSITE IS PROVIDED ON AN “AS IS,” “AS AVAILABLE”
                                        BASIS. YOU AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE
                                        RISK. FTL DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING
                                        BUT NOT LIMITED TO ANY EXPRESS WARRANTIES, STATUTORY
                                        WARRANTIES, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO
                                        THE EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON
                                        WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE
                                        AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE WEBSITE
                                        SHALL BE TO DISCONTINUE USING THE WEBSITE.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Limitation of Liability.</strong> UNDER NO
                                        CIRCUMSTANCES WILL FINTECH LAW BE LIABLE OR RESPONSIBLE FOR
                                        ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING
                                        DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR
                                        THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES,
                                        UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY
                                        RELATING TO THE WEBSITE, YOUR THE WEBSITE USE, OR THE
                                        CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                                        YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE OR
                                        CONTENT IS TO CEASE ALL OF YOUR WEBSITE USE.&nbsp; &nbsp;
                                        You may have additional rights under certain laws (including
                                        consumer laws) which do not allow the exclusion of implied
                                        warranties or the exclusion or limitation of certain
                                        damages. If these laws apply to you, the exclusions or
                                        limitations in this Agreement that directly conflict with
                                        such laws may not apply to you.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Use of Information.</strong> FinTech Law reserves
                                        the right, and you authorize us, to use and assign all
                                        information regarding the Website uses by you and all
                                        information provided by you in any manner consistent with
                                        our Privacy Policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            Mandatory Arbitration and Class Action Waiver.
                                        </strong>
                                        &nbsp; PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR
                                        LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
                                        COURT.&nbsp;
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <strong>Application.</strong> You and FinTech Law agree
                                                that these Terms affect interstate commerce and that the
                                                Federal Arbitration Act governs the interpretation and
                                                enforcement of these arbitration provisions. This
                                                Section 15 is intended to be interpreted broadly and
                                                governs any and all disputes between us including but
                                                not limited to claims arising out of or relating to any
                                                aspect of the relationship between us, whether based in
                                                contract, tort, statute, fraud, misrepresentation or any
                                                other legal theory; claims that arose before these Terms
                                                or any prior agreement (including, but not limited to,
                                                claims related to advertising); and claims that may
                                                arise after the termination of these Terms. The only
                                                disputes excluded from this broad prohibition are the
                                                litigation of certain intellectual property and small
                                                court claims, as provided below.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Initial Dispute Resolution.</strong> Most
                                                disputes can be resolved without resorting to
                                                arbitration. If you have any dispute with us, you agree
                                                that before taking any formal action, you will contact
                                                us at
                                                <a href="mailto:info@fintechlegal.io">
                                                    info@fintechlegal.io
                                                </a>{" "}
                                                and provide a brief, written description of the dispute
                                                and your contact information (including your username,
                                                if your dispute relates to an account). Except for
                                                intellectual property and small claims court claims, the
                                                parties agree to use their best efforts to settle any
                                                dispute, claim, question, or disagreement directly
                                                through consultation with FinTech Law, and good faith
                                                negotiations shall be a condition to either party
                                                initiating a lawsuit or arbitration.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Binding Arbitration.</strong> If the parties do
                                                not reach an agreed-upon solution within a period of 30
                                                days from the time informal dispute resolution is
                                                initiated under the Initial Dispute Resolution provision
                                                above, then either party may initiate binding
                                                arbitration as the sole means to resolve claims, (except
                                                as provided in section 15(g) below) subject to the terms
                                                set forth below. Specifically, all claims arising out of
                                                or relating to these Terms (including the Terms’
                                                formation, performance, and breach), the parties’
                                                relationship with each other, and/or your use of FinTech
                                                Law shall be finally settled by binding arbitration
                                                administered by the JAMS Comprehensive Arbitration Rules
                                                &amp; Procedures (“JAMS”). The JAMS rules will govern
                                                payment of all arbitration fees. FinTech Law will pay
                                                all arbitration fees for claims less than $75,000. If
                                                you receive an arbitration award that is more favorable
                                                than any offer we make to resolve the claim, we will pay
                                                you $1,000 in addition to the award. FinTech Law will
                                                not seek its attorneys’ fees and costs in arbitration
                                                unless the arbitrator determines that your claim is
                                                frivolous.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Arbitrator’s Powers.</strong> The arbitrator,
                                                and not any federal, state, or local court or agency,
                                                shall have exclusive authority to resolve all disputes
                                                arising out of or relating to the interpretation,
                                                applicability, enforceability, or formation of these
                                                Terms including but not limited to any claim that all or
                                                any part of these Terms is void or voidable, whether a
                                                claim is subject to arbitration, or the question of
                                                waiver by litigation conduct. The arbitrator shall be
                                                empowered to grant whatever relief would be available in
                                                a court under law or in equity. The arbitrator’s award
                                                shall be written and shall be binding on the parties and
                                                may be entered as a judgment in any court of competent
                                                jurisdiction.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Filing a Demand.</strong> To start an
                                                arbitration, you must do the following: (a) Write a
                                                Demand for Arbitration (“Demand”) that (i) briefly
                                                explains the dispute, (ii) lists your and FinTech Law’s
                                                names and addresses, (iii) specify the amount of money
                                                in dispute, if applicable, (iv) identify the requested
                                                location for a hearing if an in-person hearing is
                                                requested, and (v) state what you want in the dispute;
                                                (b) send one copy of the Demand to JAMS, along with a
                                                copy of these Terms and the filing fee required by JAMS;
                                                and (c) Send one copy of the Demand for Arbitration to
                                                us at info@fintechlegal.io.&nbsp; &nbsp; The parties
                                                understand that, absent this mandatory arbitration
                                                provision, they would have the right to sue in court and
                                                have a jury trial. They further understand that, in some
                                                instances, the costs of arbitration could exceed the
                                                costs of litigation and the right to discovery may be
                                                more limited in arbitration than in court. If you are a
                                                resident of the United States, arbitration may take
                                                place in the county where you reside at the time of
                                                filing, unless you and we both agree to another location
                                                or telephonic arbitration. For individuals residing
                                                outside the United States, arbitration shall be
                                                initiated in Hamilton County, Ohio, United States, and
                                                you and FinTech Law agree to submit to the personal
                                                jurisdiction of any federal or state court in Hamilton
                                                County, Ohio, United States, to compel arbitration, stay
                                                proceedings pending arbitration, or to confirm, modify,
                                                vacate, or enter judgment on the award entered by the
                                                arbitrator.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Class Action Waiver.</strong> The parties
                                                further agree that the arbitration shall be conducted in
                                                the party’s respective individual capacities only and
                                                not as a class action or other representative action,
                                                and the parties expressly waive their right to file a
                                                class action or seek relief on a class basis. YOU AND
                                                FINTECH LAWAGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                                                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
                                                AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                                                REPRESENTATIVE PROCEEDING. If any court or arbitrator
                                                determines that the class action waiver set forth in
                                                this paragraph is void or unenforceable for any reason
                                                or that an arbitration can proceed on a class basis,
                                                then the arbitration provisions set forth above shall be
                                                deemed null and void in their entirety and the parties
                                                shall be deemed to have not agreed to arbitrate
                                                disputes.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>
                                                    Exception: Litigation of Intellectual Property and
                                                    Small Claims Court Claims.
                                                </strong>
                                                Notwithstanding the parties’ decision to resolve all
                                                disputes through arbitration, either party may bring
                                                enforcement actions, validity determinations or claims
                                                arising from or relating to theft, piracy or
                                                unauthorized use of intellectual property in state or
                                                federal court with jurisdiction or in the U.S. Patent
                                                and Trademark Office to protect its intellectual
                                                property rights (“intellectual property rights” means
                                                patents, copyrights, moral rights, trademarks, and trade
                                                secrets, but not privacy or publicity rights). Either
                                                party may also seek relief in small claims court in
                                                Cincinnati, Ohio for disputes or claims within the scope
                                                of that court’s jurisdiction.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>30-Day Right to Opt-Out.</strong> You have the
                                                right to opt out and not be bound by the arbitration and
                                                class action waiver provisions set forth above by
                                                sending written notice of your decision to opt out
                                                to info@fintechlegal.io with the subject line,
                                                “ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.” The
                                                notice must be sent within the later of 30 days of your
                                                first use of the Service or within 30 days of changes to
                                                this section being announced on the Website. Otherwise,
                                                you shall be bound to arbitrate disputes in accordance
                                                with the terms of these paragraphs. If you opt out of
                                                these arbitration provisions, FTL also will not be bound
                                                by them.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Changes to This Section.</strong> FinTech Law
                                                will provide 30 days’ notice of any changes to this
                                                section by posting on the Website. Amendments will
                                                become effective 30 days after they are posted on the
                                                Website or sent to you by email. Changes to this section
                                                will otherwise apply prospectively only to claims
                                                arising after the 30th day. If a court or arbitrator
                                                decides that this subsection on “Changes to This
                                                Section” is not enforceable or valid, then this
                                                subsection shall be severed from the section entitled
                                                Mandatory Arbitration and Class Action Waiver, and the
                                                court or arbitrator shall apply the first Mandatory
                                                Arbitration and Class Action Waiver section in existence
                                                after you began using the Website.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Survival.</strong> This Mandatory Arbitration
                                                and Class Action Waiver section shall survive any
                                                termination of your use of the Website.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>
                                        <strong>Applicable Law.</strong> You agree that the laws of
                                        the State of Ohio, without regard to conflicts of laws
                                        provisions will govern these Terms and any dispute that may
                                        arise between you and FinTech Law or its affiliate&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Severability.</strong> If any provision of this
                                        Agreement shall be adjudged by any court of competent
                                        jurisdiction to be unenforceable or invalid, that provision
                                        shall be limited or eliminated to the minimum extent
                                        necessary so that this Agreement will otherwise remain in
                                        full force and effect.&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Termination.</strong>FinTech Lawmay terminate this
                                        Agreement at any time, with or without notice, for any
                                        reason&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Relationship of the Parties.</strong> Nothing
                                        contained in this Agreement or your use of the Services and
                                        Websites shall be construed to constitute either party as a
                                        partner, joint venturer, employee, or agent of the other
                                        party, nor shall either party hold itself out as such.
                                        Neither party has any right or authority to incur, assume or
                                        create, in writing or otherwise, any warranty, liability or
                                        other obligation of any kind, express or implied, in the
                                        name of or on behalf of the other party, it being intended
                                        by both parties that each shall remain independent
                                        contractors responsible for its own action
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Notices and Contact Information.</strong>&nbsp;
                                        FinTech Law&nbsp;
                                        <a href="tel:5139099607">
                                            <u>513-991-8472</u>
                                        </a>
                                        &nbsp;
                                        <a href="mailto:info@fintechlegal.io">
                                            info@fintechlegal.io&nbsp;
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>General Terms.</strong>&nbsp;
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                <strong>Force Majeure.</strong> Under no circumstances
                                                shall FinTech Lawor its licensor or supplier be held
                                                liable for any delay or failure in performance resulting
                                                directly or indirectly from an event beyond its
                                                reasonable control.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Waiver.</strong> The failure of FinTech Law to
                                                exercise or enforce any right or provision of this
                                                Agreement shall not operate as a waiver of such right or
                                                provision. Any waiver of this Agreement by FinTech Law
                                                must be in writing and signed by an authorized
                                                representative of FinTech Law.&nbsp;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Third-Party Beneficiaries.</strong> You agree
                                                that, except as otherwise expressly provided in these
                                                Terms, there shall be no third-party beneficiaries to
                                                these Terms
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Statute of Limitations.</strong> Except for
                                                residents of New Jersey, you agree that regardless of
                                                any statute or law to the contrary, any claim or cause
                                                of action arising out of or related to the use of the
                                                Website and/or these Terms must be filed within one year
                                                after such claim or cause of action arose or be forever
                                                barre
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Entire Agreement.</strong> These Terms
                                                constitute the entire agreement between you the Website,
                                                and FinTech Law, and governs the terms and conditions of
                                                your use of the Website, and supersedes all prior or
                                                contemporaneous communications and proposals, whether
                                                electronic, oral, or written, between you, the Website,
                                                and FinTech Law. Notwithstanding the foregoing, you may
                                                also be subject to additional terms and conditions,
                                                posted policies (including but not limited to the
                                                Privacy Policy), guidelines, or rules that may apply
                                                when you use the Website may revise these Terms at any
                                                time by updating this Agreement and posting it on the
                                                Websites. Accordingly, you should visit the Website and
                                                review the Terms periodically to determine if any
                                                changes have been made. Your continued use of this
                                                Website after any changes have been made to the Terms
                                                signifies and confirms your acceptance of any such
                                                changes or amendments to the Terms.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                <strong>
                                    The information provided hereby is for general informational
                                    purposes only and should not be construed as investment, tax,
                                    or legal advice or an offer or solicitation. You should
                                    consult your investment advisor, accountant, and lawyer in
                                    your state of residence before making any decision based on
                                    the information contained herein.
                                </strong>
                            </p>
                        </Col>
                        {/* <Col className='contact-second-col'>
                <h2>Contact Us</h2>
                <Form>
                    <Form.Group controlId="formFullName">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" name="fullName"  />
                    </Form.Group>
                    <Form.Group controlId="formCompanyName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" name="companyName" />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" />
                    </Form.Group>
                    <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" name="message"/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Send Message</Button>
                </Form>
                </Col> */}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Secondsec;
