import React, {useState, useEffect} from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import './resources.css'

const Intro = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-reourcespage`);
        const result = await response.json();
        setpartner(result);
    };

    useEffect(() => {
        fetchpartnerpage();
    }, []);

    return (
        <section className='blogintrosec'>
            <Container>
                <Row>
                    <Col>
                        {partner.map((row, index) => (
                            <React.Fragment key={index}>
                                <h1 className='newsletter-resource-heading'>{row.heading}</h1>
                                <p>{row.subheading}</p>
                            </React.Fragment>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Intro

