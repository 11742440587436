import React ,{useEffect,useState}from 'react'
import { Container, Row , Col } from 'react-bootstrap'

import './services.css'

const Intro = () => {
    const [partner, setpartner] = useState([]);

    const fetchpartnerpage = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/get-servicespage`);
        const result = await response.json();
        setpartner(result);
      };

      useEffect(() => {
          fetchpartnerpage();
      }, []);
      return (

        <section className="blogintrosec">
        <Container >
          <Row className='py-5'>
            <Col>
            {partner.map((row, index) => (
                    <React.Fragment key={index}>
                        <h1 className='services-top-head'>{row.heading}</h1>
                        <h2 className='py-3 services-sub-head'>{row.subheading}</h2>
                        </React.Fragment>
                    ))}

            </Col>
          </Row>
        </Container>
      </section>
    )
}

export default Intro
