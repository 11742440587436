import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'

import Intro from './Intro'
import Secondsec from './Secondsec'

import "./blog.css";

const index = () => {
  return (
        <>
        <Helmet>
        <title>
        FinTech Law Blog | Securities Law Blog | FinTech Law
            </title>
            <meta name="description" content="Follow the FinTech Law blog to learn insights and industry updates on a variety of topics including new rules and regulations, conference updates and more.
"/>
        </Helmet>
            <Header/>
            <React.Fragment>
            <Intro/>
            <Secondsec/>
            </React.Fragment>
            <Footer/>
        </>
    )
}

export default index
